import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/homepage/homepage/src/layouts/pages.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Uppps. 404. Seite nicht gefunden`}</h1>
    <p>{`Leider haben Sie einen Inhalt aufgerufen, der nicht existiert.`}</p>
    <p>{`Versuchen Sie es doch auf der `}<a parentName="p" {...{
        "href": "/"
      }}>{`Startseite`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      