import Layout from "../components/Layout"
import React from 'react'

const Pages = ( props ) => (
  <Layout title={props.pageContext.frontmatter?.title} description={props.pageContext.frontmatter?.description}>
    {props.children}
  </Layout>
)

export default Pages
